import { I18nOptions } from 'vue-i18n';
import { defaultLanguage } from '@ruokaboksi/api-client';
import fi from './locales/fi.json';
import en from './locales/en.json';
import cs from './locales/cs.json';

export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: defaultLanguage,
  globalInjection: true,
  messages: { fi, en, cs },
})) as I18nOptions;
